import Image from 'next/image';
import React, { useRef } from 'react';
import styled from 'styled-components';
import MainCTA from './MainCTA';
import { useRect } from '@reach/rect';
import { motion } from 'framer-motion';

const Banner = () => {
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);

  const imageWidth = rect?.width || 0;

  return (
    <Wrapper ref={ref}>
      <BackgroundImageWrapper>
        <Image
          src="/images/marketing/Solin_Final_Texture.png"
          layout="fill"
          priority
        />
      </BackgroundImageWrapper>

      <ImageWrapper>
        <ImageBox
          style={{ '--width': imageWidth + 'px' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Image
            src="/images/marketing/landing-page.png"
            layout="fill"
            priority
            objectFit="contain"
            objectPosition="top center"
          />
        </ImageBox>
      </ImageWrapper>
      <MainCTAWrapper>
        <MainCTA />
      </MainCTAWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: #000;
  position: relative;

  padding: 56px 105px 0;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding: 56px 64px 0;
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 56px 28px 0;
  }
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: brightness(0.4);
`;

const ImageWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-left: auto;
`;

const ImageBox = styled(motion.div)`
  position: relative;
  /* have width / (1.7 or 1.8) so it hangs slightly over the middle of the page */
  width: calc(var(--width) / 1.7 - 105px);
  height: calc(var(--width) / 1.7 - 105px);

  @media ${(p) => p.theme.queries.tabletAndDown} {
    width: calc(var(--width) / 1.8 - 64px);
    height: calc(var(--width) / 1.8 - 64px);
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    width: calc(var(--width) - 56px);
    height: calc(var(--width) - 56px);
  }

  @media (max-width: 450px) {
    margin-bottom: 50px;
  }
`;

const MainCTAWrapper = styled.div`
  position: absolute;
  /* top is added to slightly position it down the screen so it somewhat hovers
  over the image */
  top: 50%;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    top: 70%;
  }
`;

export default Banner;
